import axios from 'axios'
import { parseCookies } from 'nookies'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
})

api.interceptors.request.use((config) => {
  try {
    const cookies = parseCookies()
    const { NEXT_LOCALE } = cookies
    const locale = NEXT_LOCALE || 'ru'

    if (config.headers && !config.headers['Accept-Language']) {
      config.headers['Accept-Language'] = locale
    }
    return config
  } catch {
    return config
  }
})

export default api
