export const API_ROUTE_REGISTER_USER = '/account'
export const API_ROUTE_TOKEN_LOGIN = '/account/token_login'
export const API_ROUTE_TOKEN_LOGIN_LOCAL = '/api/api_token'
export const API_ROUTE_LOGIN = '/account/login'
export const API_ROUTE_REFRESH_TOKEN = '/account/token/refresh/'
export const API_ROUTE_SEND_EMAIL_VERIFICATION =
  '/account/request_verification_email'

export const API_ROUTE_VERIFY_EMAIL = '/account/verify_email'

export const API_ROUTE_APPLICATION = '/account/application'

export const API_ROUTE_GET_PERSONAL_DATA = '/account/passport'

export const API_ROUTE_GET_ME = '/account/me'

export const API_ROUTE_SERVICES = '/service/'

export const API_ROUTE_ACTIVE_SUBSCRIPTION = '/billing/subscription'

export const API_ROUTE_SET_USER_AVATAR = '/account/set_avatar'

export const API_ROUTE_RESET_PASSWORD = '/account/request_reset_password'

export const API_ROUTE_RESET_OPTIONS = '/account/reset_options'

export const API_ROUTE_CHECK_VERIFICATION_CODE =
  '/account/check_verification_code'

export const API_ROUTE_CONFIRM_RESET_PASSWORD =
  '/account/reset_password_confirm'

export const API_CHECK_RESET_TOKEN = '/account/reset_token'

export const API_PRIVACY_POLICY = '/privacy-policy/current/'

export const API_PRIVACY_POLICY_ALL = '/privacy-policy/'

export const API_ROUTE_CHECK_RESET_TOKEN = '/account/reset_token'

export const API_ROUTE_SET_PASSWORD = '/account/set_password'

export const API_ROUTE_SET_PHONE_NUMBER = '/account/set_phone_number'

export const API_ROUTE_VERIFY_PHONE_NUMBER = '/account/verify_phone_number'

export const API_GET_AVAILABLE_DOMAINS = '/domain/whois'

export const API_GET_DOMAINS = '/domain/'

export const API_ORDER = '/billing/order'

export const API_ORDER_ITEM = '/billing/order/item'

export const API_GET_ACTIVE_ORDER = '/billing/order/active'

export const API_USER_BALANCE = '/account/balance'

export const API_USER_BALANCE_RECHARGE_FOR_ORDER =
  '/account/balance/recharge_for_order'
export const API_USER_BALANCE_RECHARGE = '/account/balance/recharge'

export const API_UZTK_APPLICATION = 'uztk/application'

export const API_UZTK_PASSPORT = 'uztk/passport'

export const API_DICTIONARY = 'dictionary/'
export const API_DICTIONARY_REGION = 'region'

export const API_CONTACT_US = '/contact_us/'

export const API_REVIEW = '/review/'

export const API_ROUTE_GET_NEWS = '/article'

export const API_STATISTICS_FOR_DASHBOARD = '/uztk/statistics'

export const API_NOTIFICATION = '/notification/'

export const API_NOTIFICATION_SET_VIEWED = '/notification/${id}/set_viewed/'
export const API_ACTIVATE_FREE_TRIAL = '/billing/subscription/trial'
export const API_FREE_SERVICES = '/service/trial_plan'

export const API_BUSINESS = '/business/'

export const API_BUSINESS_APPLICATION = '/business/application/'
export const API_BUSINESS_APPLICATION_STATUS =
  '/business/application/check_status/'

export const API_SOCIAL_LOGIN = '/social_oauth/'
export const API_S3_BUCKET = '/billing/order/item'
export const API_KEYCLOAK_LOGIN = '/account/keycloak/login'
